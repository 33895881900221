import React, { useRef, useEffect, useState, useCallback } from 'react';

const initialPosition = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

const Swipe = () => {
  const trashRef = useRef();
  const bagRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const [targetLocation, setTargetLocation] = useState(null);
  const [shrinkFactor, setShrinkFactor] = useState(1);
  const [shirtImageUrl, setShirtImageUrl] = useState(null);
  const [shirtIndex, setShirtIndex] = useState(0);
  const imageRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  const url = process.env.REACT_APP_WORKER_URL;

  const fetchShirtImage = useCallback(async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const imageUrl = data.url;
      const index = data.index;
      setShirtImageUrl(imageUrl);
      setShirtIndex(index);
    } catch (error) {
      console.error('Error fetching shirt image:', error);
    }
  }, [url]); // Add any dependencies here

  useEffect(() => {
    fetchShirtImage();
  }, [fetchShirtImage]);

  const handleMouseDown = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setIsDragging(true);
    setPosition({ x: rect.left, y: rect.top });

    const handleMouseMove = (e) => {
      const newX = e.clientX - offsetX;
      const newY = e.clientY - offsetY;
      setPosition({ x: newX, y: newY });
    };

    const handleMouseUp = (e) => {
      setIsDragging(false);
      const { clientX } = e;
      const threshold = window.innerWidth / 2.5;
      if (clientX < threshold) {
        setTargetLocation(trashRef.current.getBoundingClientRect());
        const formData = new FormData();
        formData.append('key', shirtIndex);
        formData.append('vote', "no");
        fetch(
          url, {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: formData,
        }
        )
      } else if (clientX > window.innerWidth - threshold) {
        setTargetLocation(bagRef.current.getBoundingClientRect());
        const formData = new FormData();
        formData.append('key', shirtIndex);
        formData.append('vote', "yes");
        fetch(
          url, {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: formData,
        }
        )
      } else {
        setTargetLocation(null);
      }
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const touch = e.touches[0];
    const offsetX = touch.clientX - rect.left;
    const offsetY = touch.clientY - rect.top;
    setIsDragging(true);
    setPosition({ x: rect.left, y: rect.top });

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const newX = touch.clientX - offsetX;
      const newY = touch.clientY - offsetY;
      setPosition({ x: newX, y: newY });
    };

    const handleTouchEnd = (e) => {
      setIsDragging(false);
      const touch = e.changedTouches[0];
      const { clientX } = touch;
      const threshold = window.innerWidth / 2.5;
      if (clientX < threshold) {
        setTargetLocation(trashRef.current.getBoundingClientRect());
        const formData = new FormData();
        formData.append('key', shirtIndex);
        formData.append('vote', "no");
        fetch(
          url, {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: formData,
        }
        )
      } else if (clientX > window.innerWidth - threshold) {
        setTargetLocation(bagRef.current.getBoundingClientRect());
        const formData = new FormData();
        formData.append('key', shirtIndex);
        formData.append('vote', "yes");
        fetch(
          url, {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
          body: formData,
        }
        )
      } else {
        setTargetLocation(null);
      }
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };

    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  };

  useEffect(() => {
    const animate = () => {
      if (targetLocation) {
        const dx = targetLocation.x - position.x;
        const dy = targetLocation.y - position.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 50) {
          // Shrink the image
          const shrinkRate = 0.025; // Adjust this value to control the shrink speed
          setShrinkFactor((prevShrinkFactor) =>
            Math.max(prevShrinkFactor - shrinkRate, 0)
          );

          if (shrinkFactor === 0) {
            // Image has reached the destination, fetch a new random image.
            fetchShirtImage();
            cancelAnimationFrame(animationFrameRef.current);
            setPosition(initialPosition);
            setShrinkFactor(1);
            setTargetLocation(null);
            setAnimate(true);
            return;
          }
        } else {
          const speed = Math.min(distance / 10, 20);
          const newPosition = {
            x: position.x + (dx / distance) * speed,
            y: position.y + (dy / distance) * speed,
          };

          setPosition(newPosition);
        }
      }

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [position, targetLocation, shrinkFactor, fetchShirtImage]);

  return shirtImageUrl ? (
    <div>
      <div
        ref={imageRef}
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          cursor: isDragging ? 'grabbing' : 'grab',
          touchAction: 'none',
          zIndex: 2,
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <img
          src={shirtImageUrl}
          alt="shirt"
          draggable={false}
          style={{
            width: `${window.innerHeight * 0.45 * shrinkFactor}px`,
            height: `${window.innerHeight * 0.45 * shrinkFactor}px`,
            minWidth: '100%',
            minHeight: '100%',
            userSelect: 'none',
            transform: `scale(${shrinkFactor}) translate(-50%, -50%)`,
            position: 'absolute',
            left: "50%",
            top: "50%",
            zIndex: 2,
            animation: animate ? 'drop-down 1.2s ease-in' : '',
          }}
          onAnimationEnd={() => setAnimate(false)}
          className='shirt-image'
        />
      </div>
      <div>
        <div id='trash' ref={trashRef} className='btn btn-dark d-inline fs-6 fw-bold'>
          NO!
        </div>
        <div id='bag' ref={bagRef} className='btn btn-success d-inline fs-6 fw-bold'>
          YES!
        </div>
      </div>
    </div>
  ) : null;
};
export default Swipe;