import React from 'react';
import Swipe from './components/Swipe.jsx';
import NavigationBar from './components/Navbar.jsx';
import Submit from './components/Submit.jsx';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className='App-body'>
        <Router>
          <NavigationBar />
          <Routes>
            <Route path="/submit" element={<Submit />} />
            <Route path="/" element={<Swipe />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;