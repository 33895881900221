import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Cart } from 'react-bootstrap-icons';
import { Upload } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
    return (
        <Navbar className="App-navbar" expand="lg">
            <Container className="" id='navtainer'>
                <Navbar.Brand href="" className='fs-2 d-inline' as={Link} to="/">Would You Buy<br />This Shirt?</Navbar.Brand>
                <Nav className='nav-buttons-container'>
                    <Nav.Link id='cart' href="" className='fs-6 nav-buttons disabled'>
                        <Cart /> Cart (Coming soon)
                    </Nav.Link>
                    <Nav.Link id='submit' href="" className='fs-6 nav-buttons' as={Link} to="/submit">
                        <Upload /> Submit
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default NavigationBar;