import React from 'react';

const Submit = () => {
    return (
        <div className=''>
            <h1>Submit</h1>
            <p>Work in progress</p>
        </div>
    );
};

export default Submit;